import moment from "moment";

const SubscriptionsListItem = ({subscription, product}) => {
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return (
        <div className="subscription-item">
            {subscription.status === 'active'?
                <p className='title'>Ships on { moment(subscription.next_charge_scheduled_at).format( 'll')}</p>
                :
                <p className='title'>Cancelled subscription</p>
            }
            <img className='icon' src="/img/right-arrow.svg" alt=""/>
            <hr/>
            <div className="contents-cont">
                <div className="image-cont">
                    <img className='product-img' src={product && product.images[0]? product.images[0].medium: null} alt=""/>
                </div>
                <div className="contents">
                    <p>{subscription.product_title}</p>
                    <p className='variant-title'>{subscription.variant_title}</p>
                    <p className='price'>{USDollar.format(subscription.price)}</p>
                    {/*<p>QTY: {subscription.quantity}</p>*/}
                </div>
            </div>
        </div>
    );
};

export default SubscriptionsListItem;