const SkeletonListItem = () => {
    return (
       <li className="skeleton-item">
           <div className="subscription-item">
               <p className='title'>Hello </p>
               <hr/>
               <div className="contents-cont">
                   <div className="image-cont">
                       <div className='product-img'></div>
                   </div>
                   <div className="contents">
                       <p>hello</p>
                       <p className='variant-title'>hello</p>
                       <p className='price'>hello</p>
                   </div>
               </div>
           </div>
       </li>
    );
};

export default SkeletonListItem;