import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { useActions, useVariables } from "@tapcart/webbridge-react";
import SubscriptionListItem from "./SubscriptionListItem";
import SkeletonListItem from "./SkeletonListItem";
import SkeletonTextItem from "./SkeletonTextItem";

import {
    loginShopifyApi,
    getCustomer,
    getSubscription,
    productSearch,
    listSubscriptions,
    initRecharge,
    loginShopifyAppProxy,
} from '@rechargeapps/storefront-client';

//******************
// TODO: Variables to import: Domain, Recharge Token, Shopify Storefront Token, Shopify Session Token
//******************

initRecharge({
    storeIdentifier: 'owyn.myshopify.com',
    storefrontAccessToken: 'strfnt_4d4fc8cc576f175e21ebe3d7fdc40f86b62042385e2c4789090858c3e4830942',
    loginRetryFn: async () => {
        return await loginShopifyAppProxy();
    }
});

const SubscriptionsDetailsPage = () => {
    const { id } = useParams();
    console.log(id)
    //****************
    // State Variables
    //****************
    const [session, setSession] = useState({});
    const [customer, setCustomer] = useState({});
    const [subscription, setSubscription] = useState();
    const [products, setProducts] = useState([]);

    //****************
    // App Actions
    //****************
    const {
        addToCart,
        openProduct,
        openCollection,
        applyDiscount,
        removeDiscounts,
        showToast
    } = useActions();

    const openProductAction = (product) => {
        openProduct({ productId: product.pid.toString() });
    };

    const openCollectionAction = (collection) => {
        console.log(collection)
        openCollection({ collectionId: "174359904394" });
    };

    //****************
    // Initial API Calls
    //****************
    useEffect(() => {
        const fetchData = async () => {
            let session = await loginShopifyApi('5097bb0eba1c3fa13385fe1e88b0c2d6', '2baa5ef37dbfb119f24d2f2ab74cecdb');
            setSession(session)

            let subscription = await getSubscription(session, id, { include: ['address'] } );
            setSubscription(subscription)
            console.log(subscription)

            let products = await productSearch(session, { format_version: '2022-06' });
            setProducts(products.products)
            // console.log(products.products)

            let customer = await getCustomer(session, { include: ['addresses'] });
            setCustomer(customer)
            // console.log(customer)
        }
        fetchData().catch(console.error);
    }, []);


    return (
        <div className="app-inner">
            <div className="row">
                {subscription ?
                    <SubscriptionListItem subscription={subscription} product={products.filter(product => product.external_product_id === subscription.external_product_id.ecommerce)[0]} />
                    :
                    <SkeletonListItem/>
                }

                <div className="btn btn-large btn-dashed">Add to your order</div>
            </div>

            <div className="row">
                <p className='row-title'>Shipping</p>
                {subscription ?
                    <>
                        <p className='row-text'>{subscription.include.address.first_name} {subscription.include.address.last_name}</p>
                        <p className='row-text'>{subscription.include.address.address1}, {subscription.include.address.address1}</p>
                        <p className='row-text'>{subscription.include.address.city}, {subscription.include.address.province} {subscription.include.address.zip}</p>
                    </>
                    :
                    <>
                        <SkeletonTextItem width='40'/>
                        <SkeletonTextItem width='85'/>
                        <SkeletonTextItem width='85'/>
                    </>
                }
                <img className='icon' src="/img/right-arrow.svg" alt="Edit Shipping Address"/>
            </div>

            <div className="row">
                <p className='row-title'>Billing</p>
                {subscription ?
                    <>
                        <p className='row-text'>•••• •••• •••• 4242 09/2022</p>
                        <p className='row-text'>{subscription.include.address.first_name} {subscription.include.address.last_name}</p>
                        <p className='row-text'>{subscription.include.address.address1}, {subscription.include.address.address1}</p>
                        <p className='row-text'>{subscription.include.address.city}, {subscription.include.address.province} {subscription.include.address.zip}</p>
                    </>
                :
                    <>
                        <SkeletonTextItem width='85'/>
                        <SkeletonTextItem width='40'/>
                        <SkeletonTextItem width='85'/>
                        <SkeletonTextItem width='85'/>
                    </>
                }
                <img className='icon' src="/img/right-arrow.svg" alt="Edit Billing Address"/>
            </div>

            <div className="row">
                <p className='row-title'>Frequency</p>
                {subscription ?
                    <p className='row-text'>Delivered ({subscription.quantity}x) Every {subscription.order_interval_frequency} {subscription.order_interval_unit}s</p>
                    :
                    <>
                        <SkeletonTextItem width='85'/>
                    </>
                }
                <img className='icon' src="/img/right-arrow.svg" alt="Edit Shipping Frequency"/>
            </div>

            <div className="row">
                <p className='row-title'>Subscription History</p>
                <img className='icon' src="/img/right-arrow.svg" alt="View Subscription History"/>
            </div>
        </div>
    );
};

export default SubscriptionsDetailsPage;