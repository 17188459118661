import React, { useEffect, useState, useRef } from "react";
import { useActions, useVariables } from "@tapcart/webbridge-react";
import SubscriptionListItem from "./SubscriptionListItem";
import skeletonListItem from "./SkeletonListItem";

import {
  loginShopifyApi,
  getCustomer,
  listOrders,
  getSubscription,
  productSearch,
  listSubscriptions,
  initRecharge,
  loginShopifyAppProxy,
  updateSubscription
} from '@rechargeapps/storefront-client';
import SkeletonListItem from "./SkeletonListItem";

//******************
// TODO: Variables to import: Domain, Recharge Token, Shopify Storefront Token, Shopify Session Token
//******************

initRecharge({
  storeIdentifier: 'owyn.myshopify.com',
  storefrontAccessToken: 'strfnt_4d4fc8cc576f175e21ebe3d7fdc40f86b62042385e2c4789090858c3e4830942',
  loginRetryFn: async () => {
    return await loginShopifyAppProxy();
  }
});

const SubscriptionsListPage = () => {

  //****************
  // State Variables
  //****************
  const [filter, setFilter] = useState("active");
  const [session, setSession] = useState({});
  const [customer, setCustomer] = useState({});
  const [subscriptions, setSubscriptions] = useState([]);
  const [products, setProducts] = useState([]);

  //****************
  // App Actions
  //****************
  const {
    addToCart,
    openProduct,
    openCollection,
    applyDiscount,
    removeDiscounts,
    showToast,
    openScreen
  } = useActions();

  const openProductAction = (product) => {
    openProduct({ productId: product.pid.toString() });
  };

  const openCollectionAction = (collection) => {
    console.log(collection)
    openCollection({ collectionId: "174359904394" });
  };

  const routeToDetails = (id) => {
    const destination = { type: "web", url: "https://dx-recharge.tapcart.com/subscription/"+id }
    return openScreen({ destination });
  };

  //****************
  // Initial API Calls
  //****************
  useEffect(() => {
    const fetchData = async () => {

      let session = await loginShopifyApi('5097bb0eba1c3fa13385fe1e88b0c2d6', '2baa5ef37dbfb119f24d2f2ab74cecdb');
      setSession(session)
      // console.log(session)

      let subscriptions = await listSubscriptions(session, { sort_by: 'id-desc'});
      setSubscriptions(subscriptions.subscriptions)
      // console.log(subscriptions.subscriptions)

      let products = await productSearch(session, { format_version: '2022-06' });
      setProducts(products.products)
      // console.log(products.products)

      let customer = await getCustomer(session, { include: ['addresses'] });
      setCustomer(customer)
      // console.log(customer)

      // let updatedCustomer = await updated(session, { include: ['addresses'] });
      // setCustomer(customer)
      // console.log(customer)

    }
    fetchData().catch(console.error);
  }, []);


  return (
      <div className="app-inner">
        {/*<div className="tabs-cont">*/}
        {/*  <div className={'btn btn-tab '+ (filter === 'active'? 'active': '') } onClick={()=>setFilter("active")}>Active</div>*/}
        {/*  <div className={'btn btn-tab '+ (filter === 'active'? '': 'active') } onClick={()=>setFilter("inactive")}>Inactive</div>*/}
        {/*</div>*/}

        <button onClick={ () => routeToDetails("xxxxxxxxxxxx") }>Route</button>

        { subscriptions.length === 0?
            <ul onClick={ () => routeToDetails(subscription.id)} className="subscriptions-cont">
              <SkeletonListItem/>
              <SkeletonListItem/>
            </ul> :null
        }
        {filter === 'active' && subscriptions?
            <ul className="subscriptions-cont">
              { subscriptions.filter(subscription => subscription.status === 'active').map((subscription) => (
                  <li onClick={ () => routeToDetails(subscription.id)} key={subscription.id} >
                    <SubscriptionListItem subscription={subscription} product={products.filter(product => product.external_product_id === subscription.external_product_id.ecommerce)[0]}/>
                  </li>
              ))
              }
            </ul>
            : null
        }

        {filter === 'inactive' && subscriptions?
            <ul className="subscriptions-cont">
              { subscriptions.filter(subscription => subscription.status !== 'active').map((subscription) => (
                  <li  onClick={ () => routeToDetails(subscription.id)} key={subscription.id} >
                    <SubscriptionListItem subscription={subscription} product={products.filter(product => product.external_product_id === subscription.external_product_id.ecommerce)[0]}/>
                  </li>
              ))
              }
            </ul>
            : null
        }
      </div>
  );
};

export default SubscriptionsListPage;