import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { loadWebbridge, WebbridgeProvider } from "@tapcart/webbridge-react";
import SubscriptionsListPage from "./Components/Subscriptions/SubscriptionsListPage";
import SubscriptionDetailPage from "./Components/Subscriptions/SubscriptionDetailPage";
import "./App.css";

const webbridgeClient = loadWebbridge({ test: false });
function App() {
  return (
      <Router>
          <WebbridgeProvider webbridgeClient={webbridgeClient}>
              <div className="app-cont">
                  <Routes>
                      <Route path="/" element={<SubscriptionsListPage />} />
                      <Route path="/subscription/:id" element={<SubscriptionDetailPage />} />
                  </Routes>
              </div>
          </WebbridgeProvider>
      </Router>
  );
}

export default App;
